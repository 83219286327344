import React, { useContext, useState } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTheme } from '@mui/material';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import { ReactComponent as SettingsLogout } from 'assets/svg/logout-icon-sm.svg';
import { ReactComponent as SettingsUser } from 'assets/svg/user-icon-sm.svg';
import { AuthContext } from 'hooks/useAuth';
import { useRoles } from 'hooks/useRoles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PaperMenuList } from 'ui-kit/PaperMenuList';
import getAuthUserRole from 'utils/getAuthUserRole';
import { adminUser, salesManager, travelConsultantRole, travelCoordinatorRole } from 'utils/helpers';
import { RouterPath } from '../../common/router/router.path';
import SidebarItem from './SideBarItem';
import { LogoutMenu, MenuItems, SideBarWrapper } from './styles';

const SideBar = () => {
  const authUserRole = getAuthUserRole();
  const roles = useRoles();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorSettingsEl, setAnchorSettingsEl] = useState<null | HTMLElement>(null);
  const [openSettings, setOpenSettings] = useState(false);
  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSettingsEl(event.currentTarget);
    setOpenSettings(true);
  };
  const handleSettingsClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorSettingsEl(null);
    setOpenSettings(false);
  };

  const { setUser } = useContext(AuthContext);

  const theme = useTheme();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
    setUser(null);
  };

  const isAllowedSuppliers = () => {
    return (
      adminUser(roles, authUserRole) ||
      salesManager(roles, authUserRole) ||
      travelConsultantRole(roles, authUserRole) ||
      travelCoordinatorRole(roles, authUserRole)
    );
  };

  const isAllowedAdvisors = () => {
    return (
      adminUser(roles, authUserRole) || salesManager(roles, authUserRole) || travelCoordinatorRole(roles, authUserRole)
    );
  };

  const showUsers = adminUser(roles, authUserRole) || salesManager(roles, authUserRole);

  const settingsItems = [
    { icon: <SettingsUser />, text: 'Users', onClick: () => navigate('/users') },
    { icon: <SettingsLogout />, text: 'Logout', onClick: handleLogout }
  ];

  return (
    <SideBarWrapper>
      <Link className='logo' to='/'>
        <Logo />
      </Link>
      <MenuItems>
        <SidebarItem
          to='/'
          active={
            location.pathname === '/' ||
            location.pathname.indexOf('/tasks') > -1 ||
            location.pathname.indexOf('/trips') > -1
          }
          label={'Trips'}
        />

        {isAllowedSuppliers() && (
          <SidebarItem
            to={RouterPath.SpecificSupplier.BasePath}
            active={location.pathname.indexOf(RouterPath.SpecificSupplier.BasePath) > -1}
            label={'Suppliers'}
          />
        )}

        {isAllowedSuppliers() && (
          <SidebarItem
            to='/templates'
            active={location.pathname.indexOf('/templates') > -1 || location.pathname.indexOf('/itinerary-notes') > -1}
            label={'Templates'}
          />
        )}

        {isAllowedAdvisors() && (
          <SidebarItem to='/advisors' active={location.pathname.indexOf('/advisors') > -1} label={'Advisors'} />
        )}

        <SidebarItem
          to='/analytics'
          active={location.pathname.indexOf('/analytics') > -1 || location.pathname.indexOf('/team-workload') > -1}
          label={'Analytics'}
        />
      </MenuItems>
      <LogoutMenu
        onClick={handleSettingsClick}
        aria-controls={openSettings ? 'account-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={openSettings ? 'true' : undefined}
        sx={{
          top: '0',
          height: '58px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <AccountCircleIcon sx={{ width: '48px', height: '48px', fill: theme.palette.system.grey }} />
        <PaperMenuList
          anchorEl={anchorSettingsEl}
          open={openSettings}
          handleClose={handleSettingsClose}
          items={showUsers ? settingsItems : settingsItems.slice(1)}
        />
      </LogoutMenu>
    </SideBarWrapper>
  );
};

export default SideBar;
