import { Components } from '@mui/material';

const MuiTableRow: Components['MuiTableRow'] = {
  // styleOverrides: {
  //   root: ({ theme }: any) => ({
  //     marginTop: '10px',
  //     '&.MuiTableRow-hover:hover': {
  //       backgroundColor: `${theme.palette.primary.contrastText}`
  //     },
  //     '&.table-row': {
  //       borderRadius: '10px'
  //     },
  //     '&.table-card': {
  //       backgroundColor: 'inherit !important'
  //     }
  //   })
  // }
};

export default MuiTableRow;
