import { styled } from '@mui/material/styles';

interface IconWrapProps {
  active?: boolean;
}

const SideBarWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: theme.spacing(0.5, 4, 0.5, 4),
  height: '58px',
  color: theme.palette.text.primary,
  boxSizing: 'border-box',
  zIndex: theme.zIndex.appBar,
  '.logo': {
    width: '52px',
    height: '45.27px'
  },
  '.logo svg': {
    width: '52px',
    height: '45.27px'
  },
  'nav a': {
    padding: '8px 16px',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.text.primary
  },
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
}));

const MenuItems = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  marginLeft: '16px',
  gap: '16px'
}));

const IconWrap = styled('span')<IconWrapProps>(({ theme, active }) => ({
  display: 'flex',
  width: 24,
  height: 24,
  background: active ? theme.palette.text.white : 'none',
  svg: {
    width: 24,
    height: 24
  },
  'svg path': {
    fill: active ? theme.palette.primary.main : ''
  }
}));

const LogoutMenu = styled('button')(({ theme }) => ({
  position: 'absolute',
  top: '14px',
  right: theme.spacing(4),
  border: 0,
  outline: 0,
  background: 'none',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.text.primary,
  '&:hover': {
    cursor: 'pointer'
  },
  '&:focus': {
    outline: 0,
    border: 0,
    background: 'none'
  }
}));

export { SideBarWrapper, MenuItems, LogoutMenu, IconWrap };
