import { Components } from '@mui/material';

const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    root: {
      textTransform: 'none' // Remove the text transform globally for all buttons
    }
  }
};

export default MuiButton;
