import { ItineraryNotesPageLazy } from 'pages/ItineraryNotes';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { RouterPath } from './common/router/router.path';
import AuthProvider from './hooks/useAuth';
import { useRoles } from './hooks/useRoles';
import Layout from './Layout';
import { AdvisorPageLazy } from './pages/Advisor';
import { AdvisorsPageLazy } from './pages/Advisors';
import { AnalyticsPageLazy } from './pages/Analytics';
import { LoadingPage } from './pages/LoadingPage';
import Login from './pages/Login';
import { NotFound } from './pages/NotFound';
import RequestResetPassword from './pages/RequestResetPassword';
import ResetPassword from './pages/ResetPassword';
import Signup from './pages/Signup';
import { TasksPageLazy } from './pages/Tasks';
import { TeamWorkLoadPageLazy } from './pages/TeamWorkload';
import { TemplatesPageLazy } from './pages/Templates';
import { TemplatesAddPageLazy } from './pages/TemplatesAdd';
import { TemplatesEditPageLazy } from './pages/TemplatesEdit';
import { TripPageLazy } from './pages/Trip';
import { TripsPageLazy } from './pages/Trips';
import { UserPageLazy } from './pages/User';
import { UsersPageLazy } from './pages/Users';
import ProtectedRoute from './ProtectedRoute';
import { SpecificSupplierAccessRoles } from './specificSupplier/specific.supplier.access.roles';
import { SpecificSupplierCreateLazy } from './specificSupplier/specificSupplierCreate/specific.supplier.create.lazy';
import { SpecificSupplierListLazy } from './specificSupplier/specificSupplierList/specific.supplier.list.lazy';
import { SpecificSupplierUpdateLazy } from './specificSupplier/specificSupplierUpdate/specific.supplier.update.lazy';

function App() {
  const roles = useRoles();

  if (!roles.length) {
    return <LoadingPage />;
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AuthProvider />}>
        <Route path='signup' element={<Signup />} />
        <Route path='login' element={<Login />} />
        <Route path='request-reset-password' element={<RequestResetPassword />} />
        <Route path='reset-password' element={<ResetPassword />} />

        <Route element={<ProtectedRoute />}>
          <Route path='/' element={<Layout />}>
            <Route index element={<TripsPageLazy />} />
            <Route path='analytics' element={<AnalyticsPageLazy />} />
            <Route path='team-workload' element={<TeamWorkLoadPageLazy />} />
            <Route element={<ProtectedRoute rolesAllowed={['Super Admin', 'Sales Manager']} />}>
              <Route path='users'>
                <Route index element={<UsersPageLazy />} />
                <Route path='add' element={<UserPageLazy />} />
                <Route path=':uuid/edit' element={<UserPageLazy />} />
              </Route>
            </Route>
            <Route path='tasks' element={<TasksPageLazy />} />
            <Route
              element={
                <ProtectedRoute
                  rolesAllowed={['Super Admin', 'Sales Manager', 'Travel Consultant', 'Travel Coordinator']}
                />
              }
            >
              <Route path='advisors'>
                <Route index element={<AdvisorsPageLazy />} />
                <Route path='add' element={<AdvisorPageLazy />} />
                <Route path=':uuid/edit' element={<AdvisorPageLazy />} />
              </Route>
            </Route>
            <Route path='trips'>
              <Route path='add' element={<TripPageLazy />} />
              <Route path=':uuid' element={<TripPageLazy />} />
            </Route>

            <Route element={<ProtectedRoute rolesAllowed={SpecificSupplierAccessRoles} />}>
              <Route path={RouterPath.SpecificSupplier.BasePath}>
                <Route index element={<SpecificSupplierListLazy />} />
                <Route path='add' element={<SpecificSupplierCreateLazy />} />
                <Route path=':id/edit' element={<SpecificSupplierUpdateLazy />} />
              </Route>
            </Route>

            <Route path='templates'>
              <Route index element={<TemplatesPageLazy />} />
              <Route path='add' element={<TemplatesAddPageLazy />} />
              <Route path=':uuid/edit' element={<TemplatesEditPageLazy />} />
            </Route>

            <Route path='itinerary-notes'>
              <Route index element={<ItineraryNotesPageLazy />} />
            </Route>
          </Route>
        </Route>

        <Route path='*' element={<NotFound />} />
      </Route>
    )
  );

  return <RouterProvider router={router} fallbackElement={<LoadingPage />} future={{ v7_startTransition: true }} />;
}

export default App;
