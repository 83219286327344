import { Components } from '@mui/material';

const MuiTooltip: Components['MuiTooltip'] = {
  styleOverrides: {
    tooltip: {},
    arrow: {}
  }
};

export default MuiTooltip;
