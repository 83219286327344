import Input from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import React, { memo } from 'react';

const StyledInput = styled('input')({
  border: 'none',
  minWidth: 0,
  outline: 0,
  padding: 0,
  paddingTop: '1em',
  paddingBottom: '7px',
  flex: 1,
  backgroundColor: 'transparent',
  textOverflow: 'ellipsis',

  color: '#A6A6A6',
  fontFamily: 'Roboto',
  fontSize: '21px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '140%',
  letterSpacing: '0.84px',

  '&::placeholder': {
    opacity: 0,
    textTransform: 'uppercase'
  },
  '&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label': {
    top: '0.5rem',
    fontSize: '0.75rem'
  },
  '&:focus ~ label': {
    color: '#A6A6A6',
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '0.52px',
    textTransform: 'uppercase'
  },
  '&:-webkit-autofill': {
    alignSelf: 'stretch'
  },
  '&:-webkit-autofill:not(* + &)': {
    marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
    paddingInlineStart: 'var(--Input-paddingInline)',
    borderTopLeftRadius: 'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
    borderBottomLeftRadius: 'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))'
  }
});

const StyledLabel = styled('label')(() => ({
  position: 'absolute',
  top: 'calc((var(--Input-minHeight) - 28px) / 2)',
  transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
  color: '#666',
  fontFamily: 'Roboto',
  fontSize: '21px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '140%',
  letterSpacing: '0.84px',
  textTransform: 'uppercase',
  paddingLeft: '47px'
}));

const InnerInput = React.forwardRef<
  HTMLInputElement,
  JSX.IntrinsicElements['input'] & {
    register: any;
    identification: string;
    required: boolean;
    basicValue?: string | null;
    setFormValue?: any;
  }
>(function InnerInput({ register, identification, required, basicValue, setFormValue, ...props }, ref) {
  const id = React.useId();
  const [activeInput, setActiveInput] = React.useState<string>('');
  const [inputValue, setInputValue] = React.useState<string>('');

  return (
    <React.Fragment>
      <StyledInput
        {...props}
        ref={ref}
        id={id}
        {...register(identification, { required })}
        autoFocus={activeInput === identification || basicValue ? true : false}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && setFormValue) {
            setFormValue(`${identification}`, inputValue || '');
          }
        }}
        onChange={(e: any) => {
          setActiveInput(identification);
          if (!basicValue) {
            setInputValue(e?.target?.value || e?.currentTarget?.value || '');
          }
        }}
        value={basicValue ? basicValue : inputValue}
      />
      <StyledLabel htmlFor={id}>{props?.placeholder}</StyledLabel>
    </React.Fragment>
  );
});

type Props = {
  icon: React.ReactNode;
  placeholder: string;
  type: 'password' | 'email';
  register: any;
  required: boolean;
  identification: string;
  basicValue?: string | null;
  setFormValue?: any;
};

export default memo(function FloatingLabelInput({
  icon,
  placeholder,
  type,
  register,
  required,
  identification,
  basicValue,
  setFormValue
}: Props) {
  return (
    <Input
      startDecorator={icon}
      slots={{
        input: (props) => (
          <InnerInput
            {...props}
            register={register}
            identification={identification}
            required={required}
            basicValue={basicValue}
            setFormValue={setFormValue}
          />
        )
      }}
      slotProps={{
        input: { placeholder: placeholder, type: type }
      }}
      sx={{
        '--Input-minHeight': '60px',
        '--Input-radius': '10px'
      }}
    />
  );
});
