import { Components } from '@mui/material';

const MuiTextField: Components['MuiTextField'] = {
  defaultProps: {
    variant: 'outlined' // Default variant for all TextFields
  },
  variants: [
    {
      props: { variant: 'outlined' },
      style: ({ theme }: any) => ({
        '& .MuiInputLabel-root': {
          color: theme.palette.text.secondary // Default label color
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: theme.palette.customColors.brand.orangeKnowing // Label color when focused
        },
        '& .MuiOutlinedInput-root': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.system.active // Border color on hover
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.customColors.brand.orangeKnowing // Border color when focused
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.system.disabled // Disabled border color
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.button.error.main // Error border color
          }
        },
        '& .MuiInputLabel-root.Mui-disabled': {
          color: theme.palette.text.disabled // Disabled label color
        },
        '& .MuiInputLabel-root.Mui-error': {
          color: theme.palette.button.error.main // Error label color
        }
      })
    }
  ]
};

export default MuiTextField;
