import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',

  main: {
    paddingBottom: '60px',
    width: '100%'
  }
}));
