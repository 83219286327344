import { createTheme } from '@mui/material/styles';
import MuiAutocomplete from './components/MuiAutocomplete';
import MuiButton from './components/MuiButton';
import MuiCheckbox from './components/MuiCheckbox';
import MuiChip from './components/MuiChip';
import MuiCssBaseline from './components/MuiCssBaseline';
import MuiDialog from './components/MuiDialog';
import MuiIconButton from './components/MuiIconButton';
import MuiLinearProgress from './components/MuiLinearProgress';
import MuiMenuItem from './components/MuiMenuItem';
import MuiTab from './components/MuiTab';
import MuiTable from './components/MuiTable';
import MuiTableBody from './components/MuiTableBody';
import MuiTableHead from './components/MuiTableHead';
import MuiTableRow from './components/MuiTableRow';
import MuiTabs from './components/MuiTabs';
import MuiTextField from './components/MuiTextField';
import MuiTooltip from './components/MuiTooltip';
import MuiModal from './components/MuiModal';

export const theme = createTheme({
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Roboto',
    w_h1: {
      fontSize: 32,
      lineHeight: '39.52px',
      fontWeight: 600,
      letterSpacing: '0.25px',
      textTransform: 'none',
      fontFamily: 'Roboto'
    },
    w_h2: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '32.02px',
      letterSpacing: 0,
      textTransform: 'none'
    },
    w_h3: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '32px',
      letterSpacing: '0.15px',
      textTransform: 'none'
    },
    w_bodyLarge: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 'auto',
      letterSpacing: '0.15px',
      textTransform: 'none'
    },
    w_bodyRegular: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      textTransform: 'none'
    },
    w_bodySmall: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20.02px',
      letterSpacing: '0.17px',
      textTransform: 'none'
    },
    w_bodyXSmall: {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '18.2px',
      letterSpacing: '0.18px',
      textTransform: 'none'
    },
    w_labelLarge: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 'auto',
      letterSpacing: '0.15px',
      textTransform: 'none'
    },
    w_labelMedium: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 'auto',
      letterSpacing: 0,
      textTransform: 'none'
    },
    w_labelSmall: {
      fontSize: 13,
      fontWeight: 600,
      lineHeight: 'auto',
      letterSpacing: 0,
      textTransform: 'none'
    },
    w_labelXSmall: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '13.44px',
      letterSpacing: '0.15px',
      textTransform: 'none'
    },
    w_buttonSmall: {
      fontSize: 13,
      textTransform: 'capitalize',
      fontWeight: 500,
      lineHeight: '15.86px',
      letterSpacing: '0.46px'
    },
    w_buttonMedium: {
      fontSize: 14,
      textTransform: 'capitalize',
      fontWeight: 500,
      lineHeight: '17.36px',
      letterSpacing: '0.4px'
    },
    w_buttonLarge: {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: '18.9px',
      letterSpacing: '0.46px',
      textTransform: 'initial'
    }
  },
  palette: {
    system: {
      white: '#FFFFFF',
      black: '#000000',
      greyDark: 'rgba(112, 112, 112, 1)',
      grey: '#BDBDBD',
      greyLight: '#F5F5F5',
      active: 'rgba(0, 0, 0, 0.56)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.12)',
      hover: 'rgba(0, 0, 0, 0.04)',
      divider: 'rgba(0, 0, 0, 0.12)'
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    text: {
      primary: '#000000DE',
      secondary: '#00000099',
      disabled: '#00000061'
    },
    button: {
      primary: {
        main: '#1976D2',
        light: '#1976D2',
        dark: '#1565C0',
        focus: 'rgba(25, 118, 210, 0.30)',
        hover: 'rgba(25, 118, 210, 0.04)'
      },
      secondary: {
        main: '#9C27B0',
        light: '#9C27B0',
        dark: '#7B1FA2',
        focus: 'rgba(156, 39, 176, 0.30)',
        hover: 'rgba(156, 39, 176, 0.04)'
      },
      error: {
        main: '#D32F2F',
        light: '#D32F2F',
        dark: '#C62828',
        focus: 'rgba(211, 47, 47, 0.30)',
        hover: 'rgba(211, 47, 47, 0.04)'
      },
      warning: {
        main: '#EFC600',
        light: '#EFC600',
        dark: '#E65100',
        focus: 'rgba(239, 108, 0, 0.30)',
        hover: 'rgba(239, 108, 0, 0.04)'
      },
      info: {
        main: '##0288D1',
        light: '#0288D1',
        dark: '#01579B',
        focus: 'rgba(2, 136, 209, 0.30)',
        hover: 'rgba(2, 136, 209, 0.04)'
      },
      success: {
        main: '#2E7D32',
        light: '#2E7D32',
        dark: '##1B5E20',
        focus: 'rgba(46, 125, 50, 0.30)',
        hover: 'rgba(46, 125, 50, 0.04)'
      }
    },
    customColors: {
      brand: {
        logo: '#F68712',
        orangeKnowing: '#E67135',
        orangeLight: 'rgba(230, 113, 53, 0.1)',
        redPeace: '#B2243C',
        blackSoft: '#393C41'
      },
      green: {
        greenVital: '#51713E',
        greenGrayish: '#879E88',
        greenAccent: '#A1B297',
        greenBright: '#C6DFBF',
        greenTint: '#E7F1E4',
        greenLight: '#F3F8F1'
      },
      blue: {
        blueFaithful: '#16637D',
        blueGrayish: '#6398AF',
        blueAccent: '#6BBBC8',
        blueBright: '#86CAE7',
        blueTint: '#B6DAE5',
        blueLight: '#F0F8FA'
      },
      brown: {
        brownStrong: '#5F4B46',
        brownGrayish: '#85715C',
        brownAccent: '#D7B190',
        brownBright: '#EBCEB5',
        brownTint: '#EEE3CF',
        brownLight: '#F7F1E9'
      }
    }
  },
  components: {
    MuiTooltip,
    MuiTextField,
    MuiCheckbox,
    MuiLinearProgress,
    MuiCssBaseline,
    MuiTableBody,
    MuiTableHead,
    MuiTabs,
    MuiTab,
    MuiButton,
    MuiIconButton,
    MuiMenuItem,
    MuiTableRow,
    MuiTable,
    MuiDialog,
    MuiAutocomplete,
    MuiChip,
    MuiModal
  }
});
