import { Components } from '@mui/material';

const MuiTabs: Components['MuiTabs'] = {
  styleOverrides: {
    indicator: ({ theme }: any) => ({
      backgroundColor: theme.palette.customColors.brand.orangeKnowing,
      height: '2px'
    }),
    root: ({ theme }: any) => ({
      paddingLeft: theme.spacing(4),
      minHeight: '38px'
    })
  }
};

export default MuiTabs;
