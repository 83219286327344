import { Components } from '@mui/material';

const MuiTableBody: Components['MuiTableBody'] = {
  // styleOverrides: {
  //   root: ({ theme }: any) => ({
  //     '& .MuiTableCell-root': {
  //       borderBottom: 0,
  //       padding: '16px 20px',
  //       backgroundColor: 'inherit',
  //       '&.first-child': {
  //         paddingLeft: '20px',
  //         borderTopLeftRadius: '10px',
  //         borderBottomLeftRadius: '10px'
  //       },
  //       '&.last-child': {
  //         paddingRight: '20px',
  //         borderTopRightRadius: '10px',
  //         borderBottomRightRadius: '10px'
  //       }
  //     },
  //     '& .MuiTableRow-root': {
  //       '&.MuiTableRow-hover:hover': {
  //         backgroundColor: `${theme.palette.primary.contrastText}`
  //       }
  //     }
  //   })
  // }
};

export default MuiTableBody;
