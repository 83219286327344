export enum ReactQueryKeys {
  TRIPS = 'trips',
  TRIP_NOTES = 'tripNotes',
  TRIP_CLIENTS = 'tripClients',
  SEARCH_SERVICES = 'SEARCH_SERVICES',
  RECENT_SEARCHES = 'RECENT_SEARCHES',
  SEARCH_SERVICES_NOTES = 'SEARCH_SERVICES_NOTES',
  SEARCH_ITINERARY_NOTES = 'SEARCH_ITINERARY_NOTES',
  UPDATE_NOTES = 'UPDATE_NOTES',
  DUBLICATE_NOTES = 'DUBLICATE_NOTES',
  DELETE_NOTES = 'DELETE_NOTES',
  SEARCH_SCHEDULE = 'SEARCH_SCHEDULE',
  GET_SHEDULE_PLANS = 'GET_SHEDULE_PLANS',
  IMPORT_SCHEDULE_PLAN = 'IMPORT_SCHEDULE_PLAN',
  CREATE_SCHEDULE_PLAN = 'CREATE_SCHEDULE_PLAN',
  UPDATE_SCHEDULE_PLAN = 'UPDATE_SCHEDULE_PLAN',
  DELETE_SCHEDULE_PLAN = 'DELETE_SCHEDULE_PLAN',
  GET_USERS = 'GET_USERS',
  GET_ADVISORS = 'GET_ADVISORS',
  GET_TRIP = 'GET_TRIP',
  GET_TRIP_ANALYTICS = 'GET_TRIP_ANALYTICS',
  CREATE_TRIP = 'CREATE_TRIP',
  UPDATE_TRIP = 'UPDATE_TRIP',
  CREATE_TEAM = 'CREATE_TEAM',
  UPDATE_TEAM = 'UPDATE_TEAM',
  UPDATE_SCHEDULES = 'UPDATE_SCHEDULES',
  MOVE_TEMPLATES_TO_DATE = 'MOVE_TEMPLATES_TO_DATE',
  DUBLICATE_SCHEDULES = 'DUBLICATE_SCHEDULES',
  DUPLICATE_TEMPLATES = 'DUPLICATE_TEMPLATES',
  DELETE_SCHEDULES = 'DELETE_SCHEDULES',
  DELETE_TEMPLATES = 'DELETE_TEMPLATES',
  SCHEDULE_TEMPLATES = 'scheduleTemplates',
  FINANCE = 'finance',
  TEMPLATES = 'templates',
  SUPPLIERS = 'suppliers',
  SUPPLIER = 'supplier',
  SERVICES = 'services',
  SERVICE = 'service',
  PRICE_OPTION = 'priceOption',
  UPDATE_PRICE_OPTION = 'UPDATE_PRICE_OPTION',
  TIMING_OPTION = 'timingOption',
  TRIP_HISTORY = 'tripHistory',
  TRIP_FINANCE_HISTORY = 'tripFinanceHistory',
  UPDATE_TIMING_OPTION = 'UPDATE_TIMING_OPTION',
  SCHEDULES = 'schedules',
  SCHEDULES_HISTORY = 'schedules_history',
  ROLES = 'roles',
  ANALYTICS = 'analytics',
  USERS = 'users',
  ADVISORS = 'advisors',
  CLIENTS = 'clients',
  LOCATIONS = 'locations',
  ITINERARY_NOTES = 'itinerary_notes',
  CREATE_SCHEDULE = 'CREATE_SCHEDULE',
  UPDATE_SCHEDULE = 'UPDATE_SCHEDULE',
  DELETE_SCHEDULE = 'DELETE_SCHEDULE',
  GENERAL_SUPPLIER_ACCOMMODATION = 'GENERAL_SUPPLIER_ACCOMMODATION',
  GENERAL_SUPPLIER_GUIDE = 'GENERAL_SUPPLIER_GUIDE',
  GENERAL_SUPPLIER_PRIVATE_VEHICLE = 'GENERAL_SUPPLIER_PRIVATE_VEHICLE'
}
