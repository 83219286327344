import { Components } from '@mui/material';

const MuiTab: Components['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }: any) => ({
      ...theme.typography.w_buttonMedium,
      padding: '8px 16px',
      minHeight: '38px',
      '&.Mui-selected': {
        color: '#000'
      },
      '&:hover': {
        color: theme.palette.text.secondary
      },
      '&:hover::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '2px',
        backgroundColor: theme.palette.customColors.brand.logo,
        transition: 'opacity 0.3s'
      }
    })
  }
};

export default MuiTab;
