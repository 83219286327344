import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { AccessRole } from './common/auth/access/access.role';
import { AuthContext } from './hooks/useAuth';
import { useRoles } from './hooks/useRoles';
import RoleType from './models/role';

const checkRoleAllowed = (roles: RoleType[], userRoleId: string, allowedRoles: string[]) => {
  return roles.some((role) => {
    const userRole = roles.find((obj) => obj.id === userRoleId);

    return userRole && allowedRoles.includes(userRole.name);
  });
};

type Props = {
  rolesAllowed?: AccessRole[];
};

const ProtectedRoute = ({ rolesAllowed }: Props) => {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const roles = useRoles();

  if (!user) {
    return <Navigate to='/login' state={{ from: location }} />;
  }

  if (rolesAllowed && !checkRoleAllowed(roles, user.roleId, rolesAllowed)) {
    return <Navigate to='/' />;
  }

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <Outlet />
    </QueryParamProvider>
  );
};

export default ProtectedRoute;
