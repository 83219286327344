import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

interface SidebarItemProps {
  to: string;
  active?: boolean;
  label?: string;
}

const SidebarItem = ({ to, label }: SidebarItemProps) => {
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Link style={{ textDecoration: 'none', display: 'flex' }} to={to}>
      {label && (
        <Typography variant='w_bodyRegular' component={'label'} sx={{ alignSelf: 'center', cursor: 'pointer' }}>
          {label}
        </Typography>
      )}
    </Link>
  );
};

export default SidebarItem;
